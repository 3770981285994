import request from '@/utils/request';
// import { setAppName } from "@/utils/common";
const appName = "huanxinxiang-fangxianggan";

// 发送短信
export const code = (data) => request({
  url: '/huanxinxiang-service/v1/code/',
  method: 'post',
  headers: {
    "App-Name": appName,
  },
  data,
});

// 支付宝签约
export const codeDlipayPay = (data) => request({
  url: '/huanxinxiang-service/v1/alipay-sms-pay/',
  method: 'post',
  headers: {
    "App-Name": appName,
  },
  data,
});